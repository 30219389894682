$header-bg : $white;
$header-font : $black;

.l-header{
  width: 100%;
  height: 80px;
  padding: 0;
  transition: .3s height;
  position: absolute;
  //background: $white;
  color: $header-font;
  top:0;
  left: 0;
  box-sizing: border-box;
  align-items: center;
  @include flex;
  z-index: 999;
  padding-right: 40px;
  @include queryDown(1220px){
    padding-right: 20px;
  }
  @include tablet{
    position: fixed;
    background: $color00;
    height: 50px;
  }
  &.js-fixed{
    position: fixed;
    background: $color00;
    height: 60px;
    @include tablet{
      //background: $color01;
      height: 50px;
    }
    .l-header__cv{
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
  &__logo{
    background: $white;
    height: 100%;
    @include flex;
    justify-content: center;
    align-items: center;
    padding: 0 50px 0 40px;
    clip-path: polygon(0 0, 100% 0, 88% 100%, 0 100%);
    @include queryDown(1220px){
      padding: 0 35px 0 20px;
      clip-path: polygon(0 0, 100% 0, 84% 100%, 0 100%);
      width: 15%;
      &--img{
        width: 100%;
      }
    }
    @include tablet{
      width: 120px;
    }
  }

  &__date{
    @include flex;
    margin-left: auto;
    gap:20px;
    @include queryDown(1220px){
      display: block;
      gap: 10px;
      font-size: 1.2rem;
    }
    @include tablet{
      display: none;
    }
    &--dl{
      @include flex;
      color: $white;
      align-items: center;
      font-weight: 300;
    }
    &--dt{
      margin-right: 10px;
    }
    &--dd{

    }
  }
  &__tel{
    background: $color01;
    color: $color00;
    font-weight: 800;
    letter-spacing: 2px;
    font-size: 1.8rem;
    clip-path: polygon(8% 0, 100% 0, 100% 100%, 0 100%);
    padding: 4px 35px 4px 25px;
    margin-left: 20px;
    margin-right: -20px;
    @include flex;
    align-items: center;
    gap: 5px;
    @include queryDown(1220px){
      font-size: 1.4rem;
      letter-spacing: 1px;
      padding: 4px 30px 4px 20px;
      margin-left: 10px;
    }
    @include tablet{
      margin-left: auto;
      font-size: 1.3rem;
      display: none;
    }
    &:before{
      content: '';
      width: 22px;
      height: 22px;
      display: block;
      background: url(#{$dir}/icon-tel.svg) no-repeat center center;
      background-size: contain;
      @include tablet{
        width: 18px;
        height: 18px;
      }
    }
  }
  &__cv{
    background: $color02;
    font-weight: 600;
    padding:17px 40px 17px 25px;
    border-radius: 30px;
    position: relative;
    font-size: 1.6rem;
    z-index: 10;
    line-height: 1.0;
    @include queryDown(1220px){
      font-size: 1.4rem;
    }
    @include tablet{
      display: none;
      margin-left: auto;
      padding:10px 40px 10px 25px;
    }
    &:before{
      content: '';
      width: calc(100% + 5px);
      height: calc(100% + 5px);
      position: absolute;
      @include trans-xy();
      z-index: -1;
      display: block;
      border-radius: 30px;
      border: 2px solid $color02;
    }
    &:after{
      content: '';
      background: url(#{$dir}/arrow.svg) no-repeat center center;
      background-size: contain;
      width: 15px;
      height: 15px;
      position: absolute;
      right: 15px;
      @include trans-y();
    }
    &:hover{
      background: #FFCC24;
      &:before{
        border-color: #FFCC24;
      }
    }
  }

  &__trigger{
    display: none;
    @include tablet{
      width: 30px;
      height: 30px;
      display: block;
      margin-left: auto;
      position: relative;
      cursor: pointer;

      span{
        height: 3px;
        width: 100%;
        background: $color01;
        @include trans-xy();
        position: absolute;
        transition: .3s;
        transition-property: transform,top,left,opacity;
        &:nth-child(1){
          top:calc(50% - 11px);
        }
        &:nth-child(2){}
        &:nth-child(3){
          top:calc(50% + 11px);
        }
      }

      &.js-toggle{
        span{
          &:nth-child(1){
            @include trans-xy(45);
          }
          &:nth-child(2){
            opacity: 0;
            left: calc(50% + 10px);
          }
          &:nth-child(3){
            @include trans-xy(-45);
          }
        }
      }
    }
  }

}




.customize-support{
   #wpadminbar{
     position: fixed;
     top:0;
     left: 0;
   }

   .l-header.js-fixed{
     top: 32px;
     @include queryDown(783px){
       top: 46px;
     }
   }
  @include queryDown(783px) {
    .l-header {
      top: 46px;
    }
    #wp-admin-bar-wpseo-menu,#wp-admin-bar-new_draft{
      display: none !important;
    }
  }
  .l-globalNav{
    @include tablet{
      top:96px;
    }
  }
  .l-globalNav.js-fixed{
    top: 92px;
    //top: calc(46px);
    //height: calc(100vh - 46px);
    @include tablet{
      top:96px;
    }
  }
 }