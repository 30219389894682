// 余白ブロック
.b-support {
  max-width: 1000px;
  margin: 0 auto;
  position: relative;

  @include tablet{
    max-width: 500px;
    width: 100%;
    margin-bottom: 40px;
  }
  &__container{

  }
  &__item{
    //max-width: 260px;

    &--title{
      //text-align: center;
      font-size: 1.6rem;
      font-weight: 700;
      text-align: center;
      margin: 20px auto;
      background: $color00;
      color: $white;
      padding: 5px 10px;
      box-sizing: border-box;

      @include tablet{
        max-width: 300px;
        font-size: 1.4rem;
      }
    }
    &--text{
      //max-width: 260px;
      margin: 0 auto;
      font-weight: 500;
      @include tablet{
        max-width: 300px;
        margin: 0 auto;
      }
    }

  }
  &__img{
    max-width: 260px;
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    display: block;
    border: 3px solid $color00;
    border-radius: 50%;
  }


  &__slider{
    &--pagination{
      visibility: hidden;
      @include tablet{
        visibility: visible;
        bottom: -40px;
      }
    }

    &--prev,&--next{
      &.swiper-button-disabled{
        display: none !important;
        @include tablet{
          display: block !important;
        }

      }
    }
  }
}





