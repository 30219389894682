.c-worries{
  color: #080D3E;
  @include flex;
  gap: 30px;
  max-width: 1100px;
  margin: 0 auto;
  @include queryDown(1220px){
    max-width: 900px;
  }
  &__wrap{
    @include flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }
  &__comment{
    width: calc(100% - 460px);
    background: $white;
    border-radius: 20px;
    padding: 30px;
    box-sizing: border-box;
    position: relative;

    @include queryDown(1220px){
      width: calc(100% - 230px);
    }
    @include tablet{
      width: 100%;
      margin-bottom: 30px;
      padding: 20px;
    }

    &--title{
      display: inline;
      font-size: 2.4rem;
      font-weight: 600;
      position: relative;
      background: linear-gradient(transparent 60%, $color02 40%);
      line-height: 1.4;
      margin-bottom: 20px;
      @include tablet{
        font-size: 1.9rem;
        margin-bottom: 10px;
      }
    }
    &--lists{
      margin-top: 20px;
      padding-left: 1em;
      @include flex;
      gap: 5px;
      @include tablet{
        margin-top: 10px;
      }
    }
    &--listItem{
      font-size: 1.6rem;
      width: 100%;
      list-style:'・';
      @include tablet{
        font-size: 1.4rem;
      }
    }
  }
  &__imgWrap{
    width: 180px;
    text-align: center;
    position: relative;
    margin-bottom: -26px;
    @include tablet{
      order: 3;
    }
    &--img{
      box-sizing: border-box;
      width: 100%;
      height: 180px;
      object-fit: cover;
      border-radius: 50%;
      border: 4px solid $color02;
    }
    &--name{
      background: $color02;
      color: $black;
      padding: 8px 10px;
      box-sizing: border-box;
      display: inline-block;
      font-weight: 600;
      top: -30px;
      position: relative;
      margin-bottom: -32px;
      line-height: 1;
    }
  }

  .is-left &__comment:before{
    @include content();
    @include triangle(left,20,$white);
    top:80px;
    right:calc(100% - 1px);
  }
  .is-right &__comment:before{
    @include content();
    @include triangle(right,20,$white);
    top:80px;
    left:calc(100% - 1px);
  }
  @include tablet{

    .is-left &__comment:before,.is-right &__comment:before{
      @include content();
      @include triangle(bottom,20,$white);
      top:calc(100% - 1px);
      bottom: initial;
      right: initial;
      left: initial;
    }
    .is-left &__comment:before,.is-right &__comment:before{
      @include trans-x();
    }
  }
  .is-left &__imgWrap{
    &:nth-child(3){
      visibility: hidden;
      @include queryDown(1220px){
        display: none;
      }

    }
    @include tablet{
      margin-left: auto;
      margin-right: auto;
    }
  }
  .is-right &__imgWrap{
    &:nth-child(1){
      visibility: hidden;
      @include queryDown(1220px){
        display: none;
      }
    }
    @include tablet{
      margin-left: auto;
      margin-right: auto;
    }

  }
}