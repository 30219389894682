.l-thanks {
  padding: 40px 0;
  text-align: center;
  position: relative;
  background: #101010;
  @include tablet{
    padding: 30px 0;
  }
  &:before{
    @include content(100%,100%);
    @include trans-xy();
    width: 80%;
    background: #222222;
    background-size: cover;
    clip-path: polygon(80px 0, 100% 0, calc(100% - 80px) 100%, 0 100%);
    @include tablet{
      width: 90%;
      clip-path: polygon(60px 0, 100% 0, calc(100% - 60px) 100%, 0 100%);
    }
  }

  &__lead{
    font-size: 2.8rem;
    font-weight: 500;
    color: $white;
    line-height: 1.4;
    @include tablet{
      font-size: 1.4rem;
    }
  }
  &__img{
    display: block;
    width: 100%;
  }
}