.b-teacher {
  padding: 60px 0 40px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  @include queryDown(1300px){
    width: 90%;
  }
  @include queryDown(1220px){
    padding:40px 20px;
    width: 100%;
  }

  &__item{
    background: $color00;
    color: $white;
    @include flex;
    justify-content: space-between;
    gap:10px;
    padding: 20px;
    box-sizing: border-box;
  }

  &__img{
    width: calc(27% - 20px);
    height: 100%;
    object-fit: cover;
    aspect-ratio: 158 / 224;
    border: 4px solid $white;
    box-sizing: border-box;
    display: block;
    @include tablet{
      width: 100%;
      height: 100%;
      aspect-ratio: 3 / 2.2;
      object-fit: cover;
      //max-width: 200px;
      margin: 0 auto 10px;
    }
  }
  &__detail{
    width: 73%;
    @include tablet{
      width: 100%;
    }
  }
  &__lead{
    //margin: 10px 0;
    //font-weight: 600;
    //font-size: 1.6rem;
    @include flex;
    font-size: 1.4rem;
    &--dt{
      width: 5.5em;
    }
    &--dd{
      width: calc(100% - 5.5em);
    }
    &--ddTxt{
      width: 100%;
    }
  }
  &__job{
    background: #488B54;
    position: relative;
    z-index: 0;
    padding: 5px 10px;
    color: $white;
    overflow: hidden;
    border: 1px solid #488B54;
    margin-bottom: 20px;
    &:before{
      z-index: -1;
      @include content(calc(100% - 30px),110%,#4E9A5C);
      transform: translateX(-50%) skew(-20deg);
      left: 50%;
      top:0;
    }
  }

  &__nameWrap{
    @include flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  &__name{
    letter-spacing: .1em;
    font-weight: 600;
    font-size: 2.4rem;
  }
  &__category{
    position: relative;
    color: #080D3E;
    padding: 10px 30px;
    font-weight: 500;
    line-height: 1;
    overflow: hidden;
    z-index: 0;
    &:before{
      content: '';
      position: absolute;
      top:0;
      right: 10px;
      width: 80%;
      height: 110%;
      background: $color02;
      transform: skew(-20deg);
      z-index: -1;
    };
    &:after{
      content: '';
      position: absolute;
      top:0;
      right: 0;
      width: 50px;
      height: 110%;
      background: $color01;
      z-index: -2;
    }

  }

  .swiper-container{
    overflow: hidden;
  }
}





