// 余白ブロック
.b-spaceBlock {
  &__large {
    padding-bottom: 80px;
    display: block;
    @include tablet{
      padding-bottom: 60px;
    }
  }

  &__middle {
    padding-bottom: 60px;
    display: block;
    @include tablet{
      padding-bottom: 40px;
    }
  }

  &__small {
    display: block;
    padding-bottom: 40px;
    @include tablet{
      padding-bottom: 20px;
    }
  }
}





