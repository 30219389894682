.b-plan {
  padding: 60px 0;
  position: relative;
  z-index: 0;
  @include flex;
  justify-content: center;
  margin-bottom: -80px;

  @include queryDown(1220px){
    gap: 40px;
  }
  @include tablet{
    margin-bottom: -40px;
    gap: 40px;
  }
  &:before{
    @include content(100vw,100%,$bg-color1);
    @include trans-xy();
    z-index: -1;
  }

  &__item{
    max-width: 590px;
    box-sizing: border-box;
    position: relative;
    background: $white;
    padding:80px 30px;
    flex:1;
    color: $black;
    font-weight: 600;
    @include box-shadow();
    z-index: 0;
    @include queryDown(1220px){
      min-width: 350px;
    }
    @include tablet{
      padding: 60px 20px;
      min-width: initial;
      width: 100%;
      flex:initial;
    }
    &:before,&:after{
      @include content(100%,80px,$color01);
      left: 0;
      z-index: -1;
      @include tablet{
        height: 60px;
      }
    }
    &:nth-child(2){
      &:before,&:after{
        background-color: $color02;
      }
      &__listWrap{
        border: 4px solid $color02;
      }
    }
    &:nth-child(3){
      &:before,&:after{
        background-color: #2271b1;
      }
      &__listWrap{
        border: 4px solid #2271b1;
      }
    }
    &__listWrap{
      margin: 20px auto;
      border: 4px solid $color01;
      padding: 20px;
      border-radius: 15px;
      font-size: 1.6rem ;
      @include tablet{
        padding: 10px;
      }
      &--recommend{
        text-align: center;
        margin-bottom: 10px;
      }
    }
    &:nth-child(3) &__listWrap{
      border: 4px solid #2271b1;
    }
    &:nth-child(2) &__listWrap{
      border: 4px solid $color02;
    }
    &__lists{
      width: fit-content;
      margin: 0 auto;
      padding-left: 1em;
      //min-height: 6em;
      //font-size: 1.4rem;

    }
    &--list{
      width: fit-content;
      list-style: '・';
      font-size: 1.4rem;
    }
    &:before{
      top:0;
      clip-path: polygon(0 0,100% 0,100% 0,0 100%);
    }
    &:after{
      bottom:0;
      clip-path: polygon(0 100%,100% 0,100% 100%,0 100%);
    }
    &--lead{
      font-size: 1.6rem;
      font-weight: 800;
      margin-bottom: 20px;
      @include tablet{
        font-weight: 600;
      }
    }
    &--text{
      font-size: 1.4rem;
      font-weight: 400;
    }
    &--title{
      font-size: 2.8rem;
      font-weight: 800;
      text-align: center;
      @include tablet{
        font-size: 2.2rem;
        font-weight: 800;
      }
    }
    &--subTitle{
      text-align: center;
    }
  }
}





