.l-footer{
  background: $color00;
  padding: 80px 0 80px;
  position: relative;
  color: $white;
  @include tablet{
    padding: 60px 0 100px;
  }
  img{
    margin-left: 0;
  }
  a{
    color: $white;
  }
  .c-inner{
    @include flex;
    gap: 60px;
    @include tablet{
      gap: 20px;
    }
  }

  &__pp{
    margin-top: 108px;
    @include flex;
    gap: 20px;
    @include tablet{
      margin-top: 40px;
    }
    a{
      text-decoration: underline;
    }
  }
  &__left{
    width: 310px;
    @include tablet{
      width: 100%;
      order: 2;
      margin-top: 20px;
    }
  }
  &__right{
    width: calc(100% - 310px - 60px);
    @include tablet{
      width: 100%;

    }
  }

  &__lead{
    font-size: 1.8rem;
    line-height: 1.4;
    margin-bottom: 40px;
    @include queryDown(1220px){
      font-size: 1.6rem;
    }
    @include tablet{
      font-size: 1.6rem;
    }
  }
  &__copyright{
    font-size: 1.2rem;
  }
}