.l-cv {
  padding: 80px 0 60px;
  text-align: center;
  position: relative;
  background: $color00;
  @include tablet{
    padding: 35px 0 30px;
  }
  &:before{
    @include content(100%,100%);
    @include trans-xy();
    width: 80%;
    background: url(#{$dir}/cv-bg.jpg) center center;
    background-size: cover;
    clip-path: polygon(140px 0, 100% 0, calc(100% - 140px) 100%, 0 100%);
    @include tablet{
      width: 90%;
      clip-path: polygon(100px 0, 100% 0, calc(100% - 100px) 100%, 0 100%);
    }
  }
  .small {
    font-size: 70%;
    font-weight: 500;
  }
  &__btn--img{
    max-width: 500px;
    margin: 0 auto;
    display: block;
    position: relative;
    z-index: 0;
    @include tablet{
      max-width: 400px;
    }
  }
  &__btn {
    font-weight: 500;
    display: inline-block;
    position: relative;
    background: $color02;
    border-radius: 40px;
    box-shadow: 0 8px 0 $color01;
    //border-bottom: 8px $color01 solid;
    padding: 20px 40px;
    z-index: 0;
    &:hover{
      top:8px;
      box-shadow: 0 0 0 $color01;
      //border-bottom: 0px $color01 solid;
    }
    @include tablet{
      border-radius: 30px;
    }
    &:after{
      @include content(80px,100px,);
      @include trans-y(-20);
      filter: drop-shadow(5px 5px 50px rgba(black,.6));
      background: url(#{$dir}/panf.png) center center no-repeat;
      background-size: contain;
      top:calc(50%);
      z-index: 2;
      right: calc(100% - 20px);
      @include tablet{
        @include content(60px,80px,);
        right: calc(100% - 25px);
      }
    }
    &:before{
      @include content(calc(100% - 12px),calc(100% - 12px));
      @include trans-xy();
      z-index: 1;
      border: 3px solid $black;
      border-radius: calc(40px - 3px);
      @include tablet{
        border-radius: calc(30px - 3px);
      }
    }

    &--text{
      font-size: 1.6rem;
      @include tablet{
        font-size: 1.2rem;
      }
    }

    &--lead{
      font-size: 3.4rem;
      z-index: 1;
      line-height: 1.0;
      font-weight: 700;
      @include tablet{
        font-size: 1.8rem;
      }

      &:after {
        content: '';
        background: url(#{$dir}/arrow.svg) no-repeat center center;
        background-size: contain;
        width: 18px;
        height: 18px;
        display: inline-block;
        margin-left: 10px;
        //position: absolute;
        //right: 15px;
        //@include trans-y();
        @include tablet{
          width: 13px;
          height: 13px;
        }
      }
    }
    &--bubble {
      position: absolute;
      @include trans-x();
      top: -30px;
      width: 250px;
      z-index: 2;

      @include tablet{
        width: 160px;
        top: -15px;
      }

    }
  }
}