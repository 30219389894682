.b-access{
  margin: 0 auto;
  max-width: 1000px;
  width: 100%;
  margin-top: 60px;
  @include flex;
  gap: 10px;
  @include tablet{
    margin-top: 0;
  }
  &__item{
    width: 100%;
    font-size: 2.0rem;
    font-weight: 600;
    @include tablet{
      font-size: 1.4rem;
    }
  }
  &__trigger{
    display: block;
    position: absolute;
    width: 72px;
    height: 72px;
    right: 0;
    top:0;
    @include tablet{
      width: 62px;
      height: 62px;

    }
    &:before{
      @include content(20px,2px,$white);
      @include trans-xy();
    }
    &:after{
      @include content(20px,2px,$white);
      @include trans-xy(90);
    }
  }

  &__title{
    background: $color00;
    color: $white;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    padding: 20px;
    padding-left: 80px;
    padding-right: 80px;
    z-index: 0;
    cursor: pointer;
    @include tablet{
      padding-right: 50px;
      padding-left: 25px;
    }

    &:before{
      @include content(60px,150px);
      @include rotate(29);
      background: #4E5175;
      left: -35px;
      top: -30px;
      z-index: -1;
    }
  }
  .js-active > &__trigger{
    &:after{
      content: none;
    }
  }
  .js-active + &__detail{
    display: flex;
  }
  &__detail{
    @include flex;
    justify-content: space-between;
    margin-top: 20px;
    gap:40px;
    display: none;
  }

  &__card{
    width: calc(50% - 20px);
    @include tablet{
      width: 100%;
    }
    &--titleWrap{
      border-left: 4px solid $color00;
      padding-left: 20px;
      box-sizing: border-box;
      margin-bottom: 20px;
    }
    &--title{
      font-size: 2.0rem;
    }
    &--address{
      margin-top: 10px;
      font-weight: 500;
      font-size: 1.4rem;

    }
    &--iframe{
      width: 100%;
      display: block;
    }
    iframe{
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      aspect-ratio: 4 / 2.5;
    }
  }
}