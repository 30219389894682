.b-qa{
  margin: 0 auto;
  max-width: 1000px;
  width: 100%;
  margin-top: 60px;
  @include flex;
  gap: 10px;
  @include tablet{
    margin-top: 0;
  }
  &__item{
    width: 100%;
    font-size: 2.0rem;
    font-weight: 600;
    @include tablet{
      font-size: 1.4rem;
    }
  }
  &__trigger{
    display: block;
    position: absolute;
    width: 72px;
    height: 72px;
    right: 0;
    top:0;
    @include tablet{
      width: 62px;
      height: 62px;

    }
    &:before{
      @include content(20px,2px,$white);
      @include trans-xy();
    }
    &:after{
      @include content(20px,2px,$white);
      @include trans-xy(90);
    }
  }

  &__question{
    background: $color00;
    color: $white;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    padding: 20px;
    padding-left: 100px;
    padding-right: 80px;
    z-index: 0;
    cursor: pointer;
    @include tablet{
      padding-left: 55px;
      padding-right: 50px;
    }

    &:before{
      @include content(60px,150px);
      @include rotate(29);
      background: #4E5175;
      left: -35px;
      top: -30px;
      z-index: -1;
    }
    &:after{
      @include content(75px,calc(100% - 12px));
      content: 'Q';
      top:6px;
      @include flex;
      justify-content: center;
      //align-items: center;
      border-right: 2px solid $white;
      font-size: 2.4rem;
      padding-top: 13px;
      box-sizing: border-box;
      font-weight: 700;
      left: 0;
      @include tablet{
        width: 40px;
        font-size: 1.6rem;
      }
    }
  }
  .js-active > &__trigger{
    &:after{
      content: none;
    }
  }
  .js-active + &__answer{
    display: block;
  }
  &__answer{
    color: $color00;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    padding: 20px;
    padding-left: 100px;
    z-index: 0;
    margin-bottom: -10px;
    display: none;
    @include tablet{
      padding-left: 55px;
    }
    &:after{
      @include content(75px,calc(100% - 12px));
      content: 'A';
      top:6px;
      @include flex;
      justify-content: center;
      //align-items: center;
      padding-top: 13px;
      box-sizing: border-box;
      border-right: 2px solid $color00;
      font-size: 2.4rem;
      font-weight: 700;
      left: 0;
      @include tablet{
        width: 40px;
        font-size: 1.6rem;
      }
    }
  }
}