@charset "UTF-8";
/* CSS Document */
@import "../init/extend";

/*
  ------------------------------------
  ####################################
   #Common style
  ####################################
  ------------------------------------
*/


$dir-common: $dir + "init";
/* Reset */
@include reset;
html {
	background: $white;
	font-size: 10px;
	//font-feature-settings: 'palt';
	//background:url(#{$dir}/common/bg.jpg);
}

body {
	//min-width: $l;
	overflow: auto;
	width: 100%;
	@include fo-go;
	line-height: $line-height;
	font-size: $basefont;
	color: $color00;
	letter-spacing: 1px;
	&.is-hidden {
		overflow: hidden;
	}
	@include tablet {
		font-size: 1.4rem;
		min-width: inherit;
		width: 100%;
	}
}
figure{
	width: fit-content;
	figcaption {
		font-size: 1.4rem;
		color: #777;
		margin-top: 5px;
	}
}

.wrap {
	overflow: hidden;
	width: 100%;
	//padding-top: 100px;
	//opacity: 0;
	//transition: opacity .6s .3s;
	//&.js-load{
	//	opacity: 1;
	//}
	position: relative;
}

img{
	max-width: 100%;
	display: block;
	margin: 0 auto;
}
a {
	text-decoration: none;
	color: $black;
}

small{
	font-size: 85%;
}

.no_post{
	text-align: center;
	width: 100%;
}
.is-pc {
	display: block;
}

.is-pc-flex {
	display: flex;
}

.is-pc-ib {
	display: inline-block;
}

.is-sp,
.is-sp-flex,
.is-sp-flex,
.is-sp-ib {
	display: none !important;
}


@include tablet {
	.is-sp {
		display: block !important;
	}
	.is-sp-flex {
		display: flex !important;
	}

	.is-sp-ib {
		display: inline-block !important;
	}

	.is-pc,
	.is-pc-flex,
	.is-pc-ib {
		display: none !important;
	}
}

.red{
	color: $color00;
}

.marker{
	background: linear-gradient(transparent 60%, $color02 40%);
}
#loadWrap{
	opacity: 0;
	transition: .6s;
	position: relative;
	top:20px;
	transition-property: top,opacity,background-color;
	//background: #fff;
	&.js-load,&.js-top{
		opacity: 1;
		top:0;
	}
}