.l-column{

  &__2col{
    width: 100%;
    @include flex;
    justify-content: space-between;
    gap: 40px;
    @include tablet{
      gap:20px
    }
    &--cell{
      width: calc(50% - 20px);
      @include tablet{
        width: 100%;
        &.is-img{
          order: 2;
        }
        &.is-img.is-top{
          order: -1;
        }
      }

    }
    &.is-border{
      padding: 40px;
      box-sizing: border-box;
      border: 3px solid $color00;
      background: $white;
      @include tablet{
        padding: 20px;
      }
    }
  }
}