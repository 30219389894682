.b-quality {
  width: 100%;
  @include tablet{
    overflow: auto;
    img{
      width: 720px;
      max-width: initial;
    }
  }
}





