.l-contact {
  padding: 80px 0;
  position: relative;
  background: #4E5175;
  color: $color00;

  @include tablet {
    padding: 40px 0;
    .c-inner {
      padding: 0;
    }
  }

  &:before {
    @include content(100%, 100%, $color00);
    clip-path: polygon(0 0, 80% 0, 20% 100%, 0 100%);
    left: 0;
    top: 0;
    z-index: 0;
  }

  .c-title{
    margin-bottom: 20px;
    @include tablet{
      margin-bottom: 0;
    }
  }

  &__lead {
    font-size: 2.8rem;
    font-weight: 500;
    color: $white;
    line-height: 1.4;
    @include tablet {
      font-size: 1.4rem;
    }
  }

  &__img {
    width: 100%;
    display: block;
  }

  &__box {
    margin: 0 auto;
    width: 100%;
    padding: 90px;
    max-width: 1000px;
    background: $white;
    box-sizing: border-box;
    position: relative;
    z-index: 1;

    @include queryDown(1220px) {
      padding: 40px;
    }
    @include tablet {
      padding: 40px 20px;
    }
  }


  &__step {
    margin-top: 20px;
    @include tablet{
      margin-top: 0px;
    }
    &--bar {
      width: 100%;
      height: 10px;
      background: #e3e3e3;
      border-radius: 10px;
      position: relative;
      overflow: hidden;

      span {
        display: block;
        background: #ffeb0a;
        height: 100%;
        width: 10px;
        transition: width .3s;
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    &__wrap {
      display: flex;
      justify-content: flex-end;

      margin-bottom: 5px;

      p {
        font-weight: bold;
        color: #0A246A;
      }
    }

    &--number {
      display: flex;
      gap: 5px;
      justify-content: flex-end;

      span {
        color: #0A246A;
        font-size: 12px !important;
        font-weight: bold;
      }
    }
  }
  .loader-wrap {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: 0;
    visibility: hidden;
    left: 0;
    top: 0;
    z-index: 9999;
    background: rgba(#000,.6);
    &.js-active{
      opacity: 1;
      visibility: visible;
    }
    .loader,
    .loader:after {
      border-radius: 50%;
      width: 10em;
      height: 10em;
    }
    .loader {
      margin: 40px auto;
      font-size: 7px;
      position: relative;
      text-indent: -9999em;
      border-top: 1.1em solid rgba(255, 255, 255, 0.2);
      border-right: 1.1em solid rgba(255, 255, 255, 0.2);
      border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
      border-left: 1.1em solid #ffffff;
      -webkit-transform: translateZ(0);
      -ms-transform: translateZ(0);
      transform: translateZ(0);
      -webkit-animation: load8 1.1s infinite linear;
      animation: load8 1.1s infinite linear;
    }
  }

  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}