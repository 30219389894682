.formTable{
  @include flex;
  gap: 10px;
  text-align: left;
  margin-top: 40px;
  @extend %form-reset;
  @extend %form;
  @include tablet{
    margin-top: 20px;
  }
}
.contact-form{

  &__title{
    font-size: 1.8rem;
    letter-spacing: .1em;
    font-weight: 600;
    margin-bottom: 15px;
    @include tablet{
      font-size: 1.6rem;
      margin-bottom: 5px;
    }

    .required-text{
      display: inline-block;
      color: #F4A01B;
      padding: 0;
      background: none;
      font-weight: 500;
      font-size: 1.2rem;
      margin-left: 10px;
    }
  }

  &__wrapper{
    background: #F0F0F0;
    box-sizing: border-box;
    padding: 20px;
    width: 100%;
    @include tablet{
      padding: 10px;
    }
    &.is-half{
      width: calc(50% - 5px);
      @include tablet{
        width: 100%;
      }
    }

  }
  &__item{
    @include flex;
    gap:5px 20px;
    @include tablet{
      gap:5px 15px;
    }
  }

  &__button{
    margin: 30px auto 0;
    background: $color02;
    border-radius: 30px;
    position: relative;
    z-index: 10;
    display: block;
    max-width: 250px;
    line-height: 1.0;

    &.noEvent{
      pointer-events: none;
      background: #ccc;
      opacity: .6;
      &:before{
        border: 2px solid #ccc;
      }
    }
    input{
      color: $black !important;
    }
    @include queryDown(1220px){
      font-size: 1.4rem;
    }
    &:before{
      content: '';
      width: calc(100% + 5px);
      height: calc(100% + 5px);
      position: absolute;
      @include trans-xy();
      z-index: -1;
      display: block;
      border-radius: 32px;
      border: 2px solid $color02;
    }
    &:after{
      content: '';
      background: url(#{$dir}/arrow.svg) no-repeat center center;
      background-size: contain;
      width: 15px;
      height: 15px;
      position: absolute;
      right: 15px;
      @include trans-y();
    }
    &:hover{
      background: #FFCC24;
      &:before{
        border-color: #FFCC24;
      }
    }
    input[type='submit']{
      font-weight: 600;
      cursor: pointer;
      font-size: 1.6rem;
      padding:17px 40px 17px 25px;
      @include tablet{
        font-size: 1.5rem;
      }
    }
  }
}
