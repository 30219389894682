.b-videoBlock{
  padding: 30px 0;
  @include tablet{
    padding: 30px 0;
  }
}
.b-videoBlock__iframe{
  width: 100%;
  margin: 0 auto;
  max-width: 980px;
  display: block;
  aspect-ratio: 16 / 9;
}