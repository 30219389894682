.c-cptBnr{
  position: fixed;
  bottom:-200px;

  display: flex;
  width: 100%;
  z-index: 95;
  max-width: 500px;
  gap: 10px;
  transition:.6s bottom;
  @include queryUp(769px){
      right: 20px;
  }
  @include tablet{
    max-width: 768px;
    @include trans-x();
  }
  &.js-fixed{
    bottom:0;
  }
  @include tablet{
    gap: 0;
  }
  &__item{
    flex:1;
    padding: 23px 40px 10px 20px;
    border-radius: 15px 15px 0 0;
    font-weight: bold;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.2;
    box-sizing: border-box;
    background: black;
    color: white;
    text-align: center;
    font-size: 1.6rem;
    position: relative;
    @include tablet{
      border-radius: 0;
      font-size: 1.4rem;
    }
    &:before{
      @include content();
      @include trans-x();
      border-radius: 13px 13px 0 0;
      top: 5px;
      height: 100%;
      width: calc(100% - 10px);
      border: 2px solid $black;
      box-sizing: border-box;
      @include tablet{
        border-radius: 0;
      }
    }
    &:after{
      content: '';
      background: url(#{$dir}/arrow.svg) no-repeat center center;
      background-size: contain;
      width: 15px;
      height: 15px;
      position: absolute;
      right: 20px;
      @include trans-y();
      top: calc(50% + 6px);

    }
    &:nth-child(1){
      background: $color01;
      color: $black;
    }
    &:nth-child(2){
      background: $color02;
      color: $black;
    }
    &:nth-child(3){
      background: #2271b1;
      color: white;
      &:after{
        background-image: url(#{$dir}/arrow_wh.svg);
      }
      &:before{
        border-color: $white;
      }
    }
  }
}