.b-government {
  @include flex;
  justify-content: center;
  align-items: center;
  gap:20px 80px;
  background: $white;
  padding: 40px;
  border: 1px solid #707070;
  border-radius: 10px;
  margin: 0 auto;
  margin-top: 60px;
  max-width: 900px;
  @include queryDown(1220px){
    gap:20px 40px;
  }
  @include tablet{
    margin-top: 80px;
    gap: 10px;
    padding: 20px;
  }

  &__detail{

  }
  &__lead{
    font-size: 2.8rem;
    font-weight: 600;
    @include queryDown(1220px){
      font-size: 2.2rem;
    }
    @include tablet{
      font-size: 2rem;
    }
  }
  &__number{
    font-size: 1.6rem;
    color: #777777;
    @include tablet{
      font-size: 1.4rem;
    }
  }
}





