.c-inner{
  max-width: 1220px;
  width: 100%;
  position: relative;
  margin: 0 auto;
  box-sizing: border-box;

  @include queryDown(1220px){
    padding: 0 40px;
  }
  @include tablet {
    padding: 0 5%;
  }
}