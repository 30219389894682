.c-title{
  //color: $color00;
  text-align: center;
  margin-bottom: 60px;
  @include tablet{
    margin-bottom: 40px;
  }

  &.is-bubble{
    border: 3px solid $color00;
    padding: 40px 20px;
    position: relative;
    background: $white;
    @include tablet{
      padding: 20px 10px;
    }
    &:before{
      @include content();
      @include triangle(bottom,30,$white);
      @include trans-x();
      top:calc(100% - 1px);
      z-index: 2;
      @include tablet{
        @include triangle(bottom,20,$white);
      }
    }
    &:after{
      @include content();
      @include triangle(bottom,36,$color00);
      @include trans-x();
      top:calc(100%);
      z-index: 1;
      @include tablet{
        @include triangle(bottom,26,$color00);
      }
    }
  }

  &__sub{
    letter-spacing: .1em;
    text-align: center;
    margin-bottom: 5px;
    display: inline-block;
    &--red{
      font-size: 140%;
      font-weight: 700;
      display: inline-block;
      color: $color03;
    }
  }
  &__subRed{
    color: $white;
    box-sizing: border-box;
    padding:8px 30px;
    margin-bottom: 10px;
    display: inline-block;
    letter-spacing: .1em;
    position: relative;
    z-index: 0;
    font-weight: 600;
    &:before{
      @include content(100%,100%,$color03);
      z-index: -1;
      position: absolute;
      left: 0;
      top:0;
      transform: skew(-30deg);
    }
  }
  &__large{
    font-size: 4.0rem;
    text-align: center;
    font-weight: 600;
    letter-spacing: .1em;
    @include tablet{
      font-size: 2.2rem;
      letter-spacing: 0;
    }
  }

  &__mid{
    font-size: 2.8rem;
    font-weight: 600;
    @include tablet{
      font-size: 1.7rem;
    }
  }

  // POINT
  &__point{
    text-align: center;
    color: $color00;
    border: 3px solid $color00;
    box-sizing: border-box;
    background: $white;
    &--number{
      color: $white;
      font-size: 3.0rem;
      line-height: 1.2;
      font-weight: 600;
      padding: 10px 0;
      background: url(#{$dir}/number_bg1.svg) left center no-repeat,
                  url(#{$dir}/number_bg2.svg) right center no-repeat;
      background-color: $color00;
      background-size: contain;
      @include tablet{
        font-size: 2.0rem;
      }

    }
    &--ttl{
      display: block;
      font-size: 1.0rem;
      font-weight: 600;
    }
    &--wrap{
      padding: 30px 10px;
      @include tablet{
        padding: 20px 10px;
      }
    }
  }
}