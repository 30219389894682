.c-swiper{
  &__prev,&__next{
    width: 30px;
    height: 30px;
    display: block;
    &:after{
      content: '';
      background: url(#{$dir}/swiper-arrow.svg)no-repeat center center;
      background-size: contain;
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  &__prev{
    right: 100%;
    left: initial;
    @include queryUp(1221px){
      right: calc(100% + 15px);
    }
    @include tablet{
      right: calc(100% - 15px);
    }
    &:after{}

  }
  &__next{
    left: 100%;
    right: initial;
    @include queryUp(1221px){
      left: calc(100% + 15px);
    }
    @include tablet{
      left: calc(100% - 15px);
    }
    &:after{
      transform: rotate(180deg);

    }
  }
  &__pagination {
    position: absolute;
    width: initial;
    display: inline-block;
    right: 0;
    top: inherit;
    left: inherit;
    bottom: -15px;
    background: #4E5175;
    color: $white;
    padding: 5px 15px 5px 30px;
    font-size: 1.2rem;
    clip-path: polygon(10px 0, 100% 0, 100% 100%, 0 100%);
  }
}