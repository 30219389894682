.l-contents{
  padding: 80px 0;
  position: relative;
  color: $color00;
  //margin-bottom: 50px;
  @include tablet{
    padding: 40px 0;
  }

  &.is-blue{
    background: #1C2367;
    color: $white;
  }
  &.is-gray{
    background: $bg-color1;
  }

  &.is-line{
    background: url(#{$dir}/point_bg_top.svg) top center no-repeat,
                url(#{$dir}/point_bg_bottom.svg) bottom center no-repeat;
    background-size: 100% 140px,100% 240px;
    @include tablet{
      background-size: 100% 40px,100% 80px;
    }
    &:before{
      @include content(20px,100%,$color02);
      left: 0;
      top:0;
      z-index: -1;
      @include tablet{
        width: 10px;
      }
    }

  }

  &.is-arrow{
    margin-bottom: 50px;
    &:before{
      top:calc(100% - 1px);
      @include content(100%,51px);
      background: #1C2367;
      clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
    }

  }
}