.b-graph {
  @include flex;
  justify-content: center;
  gap: 60px;
  @include queryDown(1220px){
    gap: 20px;
  }
  @include tablet{
    gap: 20px;
  }
  &__item{
    margin: 0 0;
    display: block;

    @include queryDown(1100px){
      width: 40%;
      &:nth-child(2){
        order: 2;
      }
    }
    @include tablet{
      width: 40%;
      height: auto;
      max-width: initial;
    }
  }
}





