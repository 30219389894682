.b-attendance{
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
  @include tablet{
    width: 100%;
    max-width: 500px;
    margin-bottom: 40px;
  }
  &__container{

  }
  &__item{
    color: $black;
    //max-width: 260px;
    &--title{
      text-align: center;
      font-size: 2.2rem;
      font-weight: 700;
      margin: 20px auto;
      @include tablet{
        font-size: 1.8rem;
        margin: 20px auto 10px;
      }
    }
    &--text{
      max-width: 260px;
      margin: 0 auto;
      font-weight: 500;
    }
    &:before{
      @include content();
      @include triangle(right,25,$color00);
      top:130px;
      left: calc(100% + 30px);
      @include tablet{
        content: none;
      }
    }
    &:last-child:before{
      content: none;
    }
  }
  &__img{
    max-width: 260px;
    width: 100%;
    display: block;
    border: 2px solid $color00;
    border-radius: 50%;
  }

  &__slider{
    &--pagination{
      visibility: hidden;
      @include tablet{
        visibility: visible;
        bottom: -40px;
      }
    }
    &--prev,&--next{
      &.swiper-button-disabled{
        display: none !important;
        @include tablet{
          display: block !important;
        }

      }
    }
  }
}