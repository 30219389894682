.l-globalNav{
  width: 100%;
  position: absolute;
  top:80px;
  left: 0;
  @include flex;
  justify-content: flex-end;
  align-items: center;
  gap: 40px;
  height: 20px;
  padding-right: 40px;
  box-sizing: border-box;
  z-index: 999;
  &.is-none{
    display: none;
    @include tablet{
      display: block;
      .l-globalNav--link{
        display: none;
      }
    }
  }
  &.js-fixed{
    position: fixed;
    top:60px;
    background: $black;
    padding: 10px 0;
    padding-right: 40px;
    box-sizing: border-box;
    height: initial;
    @include queryDown(1220px){
      padding-right: 20px;
    }
  }
  &--sep{
    display: none;
    @include tablet{
      display: block;
    }
  }
  &__date{
    @include flex;
    justify-content: center;
    gap:5px 20px;
    &--dl{
      text-align: center;
      width: 100%;
      font-size: 1.2rem;
      @include flex;
      justify-content: center;
      color: $white;
      gap: 10px;
    }
  }
  @include queryDown(1220px){
    padding-right: 20px;
    top:88px;
    gap: 20px;
  }
  @include tablet{
    width: 80%;
    max-width: 440px;
    right: -100%;
    top: 50px;
    height: calc(100vh - 50px);
    position: fixed;
    margin-left: auto;
    display: block;
    background: $color00 !important;
    padding:10px 25px;
    transition: .3s right;
    &.js-toggle{
      right: 0;
    }
    &.js-fixed{
      height: calc(100vh - 50px);
      top: 50px;
      padding:10px 25px;
    }

    &--tel{
      color: $color00;
      font-weight: 800;
      letter-spacing: 2px;
      font-size: 1.4rem;
      padding:7px 10px;
      margin: 20px auto;
      box-sizing: border-box;
      @include flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      position: relative;
      &:after{
        @include content(100%,100%,$color01);
        //transform: skew(-30deg);
        z-index: -1;
      }
      &:before{
        content: '';
        width: 18px;
        height: 18px;
        display: block;
        background: url(#{$dir}/icon-tel.svg) no-repeat center center;
        background-size: contain;
      }
    }
    &--cv{
      background: $color02;
      font-weight: 600;
      padding:15px 40px 15px 25px;
      border-radius: 30px;
      position: relative;
      font-size: 1.4rem;
      width: 100%;
      display: block;
      box-sizing: border-box;
      text-align: center;
      max-width: 300px;
      line-height: 1.0;
      margin: 0 auto 20px;
      &:before{
        content: '';
        width: calc(100% + 5px);
        height: calc(100% + 5px);
        position: absolute;
        @include trans-xy();
        z-index: -1;
        display: block;
        border-radius: 30px;
        border: 2px solid $color02;
      }
      &:after{
        content: '';
        background: url(#{$dir}/arrow.svg) no-repeat center center;
        background-size: contain;
        width: 12px;
        height: 12px;
        position: absolute;
        right: 15px;
        @include trans-y();
      }
    }

  }
  &--link{
    color: $white;
    position: relative;
    font-weight: 400;
    &:not(:nth-last-of-type(1)):after{
      @include content(20px,1px,$white);
      @include trans-y(-60);
      right: -30px;
      @include queryDown(1220px){
        right: -20px;
      }
      @include tablet{
        content: none;
      }
    }
    @include queryDown(1220px){
      font-size: 1.2rem;
    }
    @include tablet{
      display: block;
      border-bottom: 1px solid $white;
      font-size: 1.4rem;
      padding: 10px 0;
    }
  }
  &__overlay{
    @include tablet{
      position: fixed;
      width: 100%;
      height: 100vh;
      background: $white;
      z-index: 995;
      opacity: 0;
      visibility: hidden;
      transition: .3s;
      transition-property: opacity,visibility;
      &.js-toggle{
        opacity: .4;
        visibility: visible;
      }
    }
  }
}


