.b-docswellBlock{
  padding: 30px 0;
  text-align: center;
  .docswell-link{
    margin-top: 10px;
  }
  .docswell-iframe-wrapper > div{
    border: 1px solid #333;
    max-width: 980px !important;
  }
  @include tablet{
    padding: 30px 0;
  }

}