// 余白ブロック
.b-student {
  padding: 40px 0;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  @include queryDown(1300px){
    width: 90%;
  }
  @include queryDown(1220px){
    padding:40px 20px;
    width: 100%;
  }
  &:before{
    @include content(100vw,100%,$bg-color1);
    @include trans-x();
    top:0;
    z-index: -1;
  }

  &__container{
    padding-top: 60px;
  }

  &__comment{
    text-align: center;
    //display: inline-block;
    background: $white;
    box-sizing: border-box;
    font-weight: 600;
    padding:10px 3px;
    margin:0 auto 20px;
    border-radius: 15px;
    position: absolute;
    top: -60px;
    width: calc(100% - 10px);
    filter: drop-shadow(0px 2px 2px rgba(0,0,0,0.19));
    @include trans-x();
    @include tablet{
      font-size: 1.2rem;
    }

    &:before{
      @include content();
      @include triangle(bottom,13,$white);
      top:calc(100% - 1px);
      @include trans-x();
    }
  }
  &__img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    aspect-ratio: 4 / 3;
    display: block;
  }
  &__lead{
    margin: 10px 0;
    font-weight: 600;
  }
  &__job{
    background: #488B54;
    position: relative;
    z-index: 0;
    padding: 5px 10px;
    color: $white;
    overflow: hidden;
    border: 1px solid #488B54;
    &:before{
      z-index: -1;
      @include content(calc(100% - 30px),110%,#4E9A5C);
      transform: translateX(-50%) skew(-20deg);
      left: 50%;
      top:0;
    }
  }
  &__name{
    margin-top: 5px;
    text-align: right;
    font-weight: 500;
    letter-spacing: .1em;
  }

  .swiper-container{
    overflow: hidden;
    @media screen and (max-width: 550px) {
      overflow: initial;
    }
  }
  @include queryDown(550px){
    .swiper-slide-next,.swiper-slide-prev{
      opacity: .6;
    }
  }
}





