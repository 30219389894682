.l-point{
  position: relative;
  padding-bottom: 40px;
  margin-bottom: 40px;
  z-index: 1;
  &:before{
    @include content(100%,100%,$bg-color1);
    @include trans-x();
    max-width: 1100px;
    z-index: -1;
    @include queryDown(1220px){
      width: 90%;
    }
  }
  &.is-arrow{
    margin-bottom: 90px;
    &:after{
      top:calc(100% - 1px);
      @include content(190%,51px);
      max-width: 1100px;
      background: $bg-color1;
      clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
      @include trans-x();
      @include queryDown(1220px){
        width: 90%;
      }
    }

  }
}