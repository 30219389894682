.p-lp{
  color: $white;

  .c-2col{
    &__half{
      border-bottom: 1px solid #EFD284;
    }
  }

  .c-ttl{
    color: $white;
    &:before{
      border-bottom: 1px solid #EFD284;
    }
  }
}

.c-sns.is-lp{
  opacity: 0;
  visibility: hidden;
}