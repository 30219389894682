.l-mainVisual {
  padding-top: 115px;
  background: url(#{$dir}/kv-bg.jpg);
  background-size: cover;
  position: relative;
  @include tablet {
    background: none;
    padding-top: 50px;
  }

  &__inner {
    position: absolute;
    max-width: 1600px;
    width: 100%;
    margin: 0 auto;
    z-index: 12;
    @include trans-x();
    @include tablet {
      display: none;
    }
  }

  &__mvImg {
    width: 63%;
    height: 100%;
    max-height: 700px;
    object-fit: cover;
    object-position: right bottom;
    clip-path: polygon(38% 0, 100% 0, 100% 100%, 0 100%);
    display: block;
    margin-left: auto;
    margin-right: 0;
    @include queryUp(1221px){
      clip-path: polygon(400px 0, 100% 0, 100% 100%, 0 100%);
    }
    @include queryDown(1220px) {
      width: 80%;
    }
    @include tablet{
      display: none;
    }
  }

  &__detail {
    padding-top: 20px;
    width: 100%;
    height: 100%;
    //position: absolute;
    left: 0;
    box-sizing: border-box;
    padding-left: 40px;
    z-index: 10;
    @include queryDown(1220px) {
      padding-left: 20px;
    }
  }

  &__detailImg {
    max-width: 100%;
    margin-left: 0;
    height: 180px;
    @include queryDown(1220px) {
      height: 120px;
    }
  }

  &__detailLead {
    margin-top: 20px;
    letter-spacing: .1em;
    color: $white;
    font-size: 3.6rem;
    font-weight: 500;
    line-height: 1.4;
    text-shadow: 3px 3px 3px rgba(#000, .3);
    width: 100%;
    @include queryDown(1000px) {
      font-size: 3.0rem;
    }

    em {
      font-size: 130%;
    }
  }

  &__detailSubLead {
    margin-top: 20px;
    padding: 5px 20px;
    position: relative;
    display: inline-block;
    margin-left: 20px;
    color: $white;
    font-weight: 600;
    //font-size: 2.7rem;
    font-size:3.5rem;
    z-index: 0;
    @include queryDown(1220px){
      //font-size: 2.4rem;
      font-size: 2.8rem;
    }
    &:before {
      @include content(100%, 100%, #B70D18);
      left: 0;
      top: 0;
      transform: skewX(-20deg);
      z-index: -1;
    }
  }

  &__imgSp {
    display: none;
    @include tablet {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &__cv {
    //position: absolute;
    //left: 40px;
    //bottom:40px;
    margin-top: 60px;
    position: relative;
    padding-left: 80px;
    @include queryDown(1220px){
      margin-top: 40px;
      padding-left: 60px;
      bottom:0;
      left: 0;
    }
    .small{
      font-size: 70%;
      font-weight: 500;
    }
    &--svg{
      left: 0;
      bottom:-6px;
      position: absolute;
      z-index: 2;
      @include queryDown(1220px){
        width: 80px;
        height: 80px;
      }
    }
    &--text{
      font-size: 1.1rem;
      color: $white;
      margin-bottom: 5px;
      padding-left: 25px;
    }
    &--btn {
      background: $color02;
      font-weight: 700;
      padding: 13px 40px 13px 30px;
      border-radius: 30px;
      position: relative;
      font-size: 2.2rem;
      display: inline-block;
      z-index: 1;
      line-height: 1.0;;
      @include queryDown(1220px){
        font-size: 1.8rem;
      }
      &:before {
        content: '';
        width: calc(100% + 5px);
        height: calc(100% + 5px);
        position: absolute;
        @include trans-xy();
        z-index: -1;
        display: block;
        border-radius: 30px;
        border: 2px solid $color02;
      }

      &:after {
        content: '';
        background: url(#{$dir}/arrow.svg) no-repeat center center;
        background-size: contain;
        width: 12px;
        height: 12px;
        position: absolute;
        right: 15px;
        @include trans-y();
      }

      &:hover {
        background: #FFCC24;

        &:before {
          border-color: #FFCC24;
        }
      }
    }
  }

  &__cv--img{
    margin: 60px 0 0;
    max-width: 400px;
    display: block;
    &:hover{
      opacity: .6;
    }
    img{
      max-width: 100%;
    }
    @include queryDown(1220px){
      max-width: 300px;
      margin-top: 40px;
    }
  }

  &__caution{
    position: absolute;
    top:calc(100% + 10px);
    text-align: right;
    padding-right: 20px;
    display: block;
    box-sizing: border-box;
    font-size: 1.2rem;
    z-index: 9;
    width: 100%;

    @include tablet {
      display: none;
    }

  }


}