.b-flow{
  @include flex;
  justify-content: space-around;
  gap: 40px;

  @include tablet{
    gap: 20px;
  }

  &__item{
    @include gap(40px,3);
    position: relative;
    padding-top: 20px;
    color: #222222;
    @include tablet{
      @include gap(20px,2);
    }
    @include queryDown(500px){
      @include gap(20px,1);
    }
  }

  &__number{
    position: absolute;
    background: $color00;
    color: $white;
    top:0;
    left: 0;
    box-sizing: border-box;
    padding:10px 30px 10px 20px;
    font-size: 1.8rem;
    clip-path: polygon(0 0,100% 0,calc(100% - 20px) 100%,0 100%);
  }
  &__img{
    border: 1px solid $color00;
    box-sizing: border-box;
    object-fit: cover;
    aspect-ratio: 4 / 2.5;
    margin-bottom: 20px;
    width: 100%;
    @include tablet{
      margin-bottom: 10px;
    }
  }
  &__title{
    font-size:2rem ;
    line-height: 1.4;
    font-weight: 800;
    margin-bottom: 20px;
    min-height: 2.8em;
    @include tablet{
      font-size: 1.8rem;
      margin-bottom: 10px;
      min-height: initial;
    }
  }
  &__txt{
    font-weight: 600;
  }
}