.l-commonLinks{
  padding: 140px 0 140px;
  @include tablet{
    padding: 100px 0;
  }
  &__flex{
    @include flex;
    justify-content: center;
    align-items: flex-start;
    gap: 60px;
    @include tablet{
      gap: 30px;
    }
  }

  &__item{
    @include gap(60px,2);
    background: #f0f0f0;
    padding: 110px 40px;
    box-sizing: border-box;
    border-radius: 10px;
    transition: opacity .3s;
    position: relative;
    &:after{
      @include content(28px,28px);
      //background: url(#{$dir}/common/arrow.svg);
      background-size: contain;
      position: absolute;
      right: 20px;
      bottom:20px;
      transition: right .3s;
      @include tablet{
        @include content(20px,20px);
      }
    }
    @include tablet{
      width: 85%;
      padding: 60px 20px;

    }
    &:nth-child(even){
      margin-top: 60px;
      @include tablet{
        margin-top: 0;
      }
    }
    &:hover{
      opacity: .6;
      &:after{
        right: 25px;
      }
    }
    &--ttl{
      //@include web-font01;
      @include web-font02;
      text-align: center;
      font-size: 3.4rem;
      @include tablet{
        font-size: 2.4rem;
      }
    }
    &--subTtl{
      width: 100%;
      text-align: center;
      color: #666666;
      @include tablet{
        font-size: 1.3rem;
      }
    }
  }
  .is-about &__item{
    &:nth-child(1){
      background: none;
      border: 2px solid $color00;
      box-sizing: border-box;
      &:after {
        background: url(#{$dir}/common/arrow-line.svg);
        background-size: contain;
      }
    }
    &:nth-child(2){
      @include bg-grad-mlt(-90,#0dc0cb,#0eace1);
      box-sizing: border-box;
      color: $white;
      .l-commonLinks__item--subTtl{
        color: $white;
      }
      &:after {
        background: url(#{$dir}/common/arrow-wh.svg);
        background-size: contain;
      }
    }

  }
}