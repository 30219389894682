.b-data {
  @include flex;
  justify-content: center;
  margin: 0 auto;
  @include queryDown(1200px){
    max-width: 550px;
    gap:10px;
  }
  @include tablet{
    justify-content: center;
    max-width: 550px;

    gap:10px;

  }
  &__item{
    width: 270px;
    position: relative;
    @include gap(10px,4);
    @include queryDown(1200px){
      @include gap(10px,2);
    }
    @include tablet{
      @include gap(10px,2);
      img{
        width: 100%;
      }
    }

    &.contents_no1 span{
      position: absolute;
      transform: translate(-50%,0);
      left: calc(50% - 10px);
      top: 85px;
      font-size: 3.6rem;
      font-weight: 700;
      color: #070a49;
      @include queryDown(610px){
        top: 31.8%;
        font-size: 5.7vw;
        left: 46%;
      }
    }
    &.contents_no2 span{
      position: absolute;
      transform: translate(-50%,0);
      left: calc(50% - 59px);
      top: 88px;
      font-size: 3.4rem;
      font-weight: 700;
      color: #070a49;
      @include queryDown(610px){
        top: 32.8%;
        font-size: 5.5vw;
        left: 28%;
      }
    }
    &.contents_no3 span{
      position: absolute;
      transform: translate(-50%,0);
      left: calc(50% - 10px);
      top: 72px;
      font-size: 3.6rem;
      font-weight: 700;
      color: #070a49;
      @include queryDown(610px){
        top: 26.8%;
        font-size: 5.7vw;
        left: 46%;
      }
    }
    &.contents_no4 span{
      position: absolute;
      transform: translate(-50%,0);
      left: calc(50% + 67px);
      top: 184px;
      font-size: 3.2rem;
      font-weight: 700;
      color: #070a49;
      @include queryDown(610px){
        top: 68.8%;
        font-size: 5.0vw;
        left: 75%;
      }
    }
  }
}





