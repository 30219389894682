.lg-backdrop{
	background: #181818 !important;
}
.lg-toolbar{
	opacity: 1 !important;
	transform: translate(0,0) !important;
	background: rgba(#000,0) !important;
}
.lg-prev,.lg-next{
	opacity: 1 !important;
	transform: translate(0,0) !important;
}