.c-edit{
  color: $color00;
  line-height: 1.6;

  h2{
    font-size: 2.8rem;
    font-weight: 700;
    margin-bottom: 15px;
    @include queryDown(1000px){
      font-size: 2.2rem;
    }
    @include tablet{
      font-size: 2.0rem;
    }
  }
  h3,h4,h5,h6{
    font-size: 2.2rem;
    font-weight: 700;
    margin-bottom: 20px;
    @include queryDown(1000px){
      font-size: 1.8rem;
    }
    @include tablet{
      font-size: 1.7rem;
    }
  }
  p{
    font-size: 1.6rem;
    margin-bottom: 1em;
    font-weight: 500;
    @include tablet{
      font-size: 1.4rem;
    }
  }
  img{
    max-width: 100%;
    //display: block;
    @include tablet{
      margin: 0 auto;
    }
  }
  figcaption{
    font-size: 1.4rem;
    color: #777777;
    margin-top: 5px;
    @include tablet{
      font-size: 1.2rem;
    }
  }

  .bubble{
    background: #4e5175;
    border: 3px solid $color00;
    padding: 8px 20px;
    border-radius: 5px;
    display: inline-block;
    margin-bottom: 15px;
    font-weight: 600;
    color: $white;
    position: relative;
    @include tablet{
      font-size: 1.2rem;
    }
    &:after{
      z-index: 1;
      @include content();
      @include arrow(bottom,15,$color00);
      left: 15px;
      top:100%;
    }
    &:before{
      z-index: -1;
      @include content();
      @include arrow(bottom,18,$color00);
      left: 14px;
      top:100%;
    }
  }
  table{
    max-width: 100%;
    th,td{
      border: 1px solid #070a49;
      padding: 5px 10px;
    }
    th{
      background: #070a49;
      color: white;
      text-align: center;
    }
  }
  ul{
    li{
      padding-left: 1.3em;
      position: relative;
      &:before{
        content: '';
        left: 0;
        top: .7em;
        width: 10px;
        height: 10px;
        background: $color00;
        position: absolute;
      }
    }
  }
  ol{
    counter-reset: section;
    li{
      padding-left: 1.5em;
      position: relative;
      &:before{
        counter-increment: section;
        content: counter(section) ".";
        left: 0;
        top: 0;
        position: absolute;
      }
    }
  }
}

.c-title + .c-edit{
  margin-top: -30px;
}

.c-title__point + .c-edit,.c-title + .l-column__2col{
  margin-top: initial;
}