.b-price {
  &__box{

    padding: 40px;
    background: $bg-color1;
    margin-bottom: 40px;
    @include queryDown(1220px){
      padding: 40px 20px;
    }
    @include tablet{
      //padding: 20px;
    }
    &--title{
      text-align: center;
      font-size: 1.8rem;
      font-weight: 800;
      margin-bottom: 40px;
      @include tablet{
        margin-bottom: 20px;
      }
    }
    &__plans{
      @include flex;
      justify-content: center;
      align-items: center;
      gap: 40px;
      @include queryDown(1220px){
        gap: 20px;
      }
      @include tablet{
        flex-flow: column;
      }
    }
    &__item{
      border: 5px solid $color00;
      border-radius: 15px;
      overflow: hidden;
      min-width: 300px;
      @include queryDown(1220px){
        min-width: 220px;
      }
      @include tablet{
        min-width: 300px;
      }
      &--title{
        background: $color00;
        font-size: 1.6rem;
        font-weight: 600;
        letter-spacing: .1em;
        color: $white;
        position: relative;
        text-align: center;
        padding: 10px;
        border-bottom: 5px solid $color00;
        z-index: 0;
        &:before{
          @include content(calc(100% - 30px),100%,#4E5175);
          z-index: -1;
          top:0;
          left: 50%;
          transform: translateX(-50%) skew(-20deg);
        }
      }
      &--detail{
        background: $white;
        @include flex;
        justify-content: center;
        align-items: baseline;
        gap: 15px;
        padding: 20px 50px;
        font-size: 4.4rem;
        font-weight: 800;
        @include queryDown(1220px){
          padding: 20px 30px;
          font-size: 3.0rem;
        }

        .en{
          font-size: 1.6rem;
          position: relative;
          line-height: 1.2;
          &:after{
            content: '(税込)';
            position: absolute;
            font-size: 1.2rem;
            font-weight: 400;
            min-width: 50px;
            left: -.8em;
            bottom:100%;
          }
        }
      }

      &--plus{
        width: 40px;
        height: 40px;
        position: relative;
        &:before,&:after{
          @include content(100%,5px,$color00);
          @include trans-xy();
        }
        &:after{
          @include trans-xy(90);
        }
      }
    }
  }
  &__payment {
    border: 1px solid $color00;
    margin: 0 auto 80px;
    max-width: 1000px;
    box-sizing: border-box;
    padding: 40px 60px;
    color: $black;
    @include flex;
    gap: 20px;
    border-radius: 20px;
    @include queryDown(1220px){
      padding: 40px 20px;
    }
    @include tablet{
      padding: 20px;
      margin-bottom: 40px;
    }

    &--dl{
      @include flex;
      font-size: 1.6rem;
      @include tablet{
        font-size: 1.4rem;
      }
    }
    &--dt{
      width: 200px;
      padding-right: 20px;
      text-align: right;
      box-sizing: border-box;
      @include tablet{
        width: 200px;
        margin-bottom: 10px;
        font-size: 1.6rem;
        font-weight: 600;
        text-align: left;
      }
    }
    &--dd{
      width: calc(100% - 200px);
      @include tablet{
        width: 100%;
      }
    }
  }

}





